<template>
	<v-container>
		<vg-loading :loading="loading"></vg-loading>
		<v-row justify="center" dense>
			<v-col lg="6" sm="12">
				<v-toolbar class="elevation-2" dark>
					<v-toolbar-title>รายชื่อนักเรียน</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn color="primary" v-if="false" @click.stop="importDialog = true">เพิ่มข้อมูลนักเรียน</v-btn>
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row justify="center"  dense>

			<!-- <v-col lg="6" sm="12" > -->
				<v-col lg="2" md="3" sm="3" xs="12" class="col-12" >
					<v-select v-model="pagination.limit" outlined label="จำนวนรายการ" dense :items="limit" @change="changeLimit" hide-details></v-select>
				</v-col>
				<v-col lg="4" md="9" sm="9" xs="12" class="col-12">
					<v-text-field 
						label="ค้นหา"
						class="pb-0 mb-0"
						prepend-inner-icon="mdi-magnify"
						v-model="search"
						outlined
						dense
						clearable
						hide-details
						@change="onChangeSearch">
					</v-text-field>
				</v-col>
			<!-- </v-col> -->


			
		</v-row>
		<template v-for="(item, index) in students">
			<v-row :key="index" justify="center" dense>
				<v-col lg="6" sm="12">
					<v-card>
						<v-card-title class="black white--text">{{ item.fullname }} </v-card-title>
						<v-divider></v-divider>
						<v-card-text>
							<v-card-subtitle class="py-1">เลขประจำตัว: {{ item.student_number }}</v-card-subtitle>
							<v-chip style="float: right" :color="getCaseColor(item.case_number)">{{ item.case_number }} คดี</v-chip>
							<v-card-subtitle class="py-1">ชั้น: {{ item.class_room }}</v-card-subtitle>
							<v-card-subtitle class="py-1">ครูประจำชั้น: {{ item.teacher_name }}</v-card-subtitle>
							<v-card-subtitle class="py-1">คดีล่าสุด: {{ item.recent_case }}</v-card-subtitle>
						</v-card-text>
						<v-divider></v-divider>
						<v-card-actions>
							<v-btn x-large color="primary" :to="'/student/' + item._id + '/student-cases'">รายงาน</v-btn>
							<v-spacer></v-spacer>
							<v-btn x-large color="primary" :to="'/students/' + item._id">ดูข้อมูล</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</template>
		<v-row justify="center">
			<v-col lg="6" sm="12">
				<v-pagination v-model="pagination.current" :length="pagination.pages" @input="getStudents"></v-pagination>
			</v-col>
		</v-row>
		<!-- <v-btn color="primary"  dark large absolute bottom right fab class="mb-10">
            <v-icon>mdi-plus</v-icon>
    </v-btn>-->
		<v-dialog v-model="importDialog" persistent max-width="500">
			<v-card>
				<v-card-title class="headline">อัพโหลดเอกสารรายชื่อนักเรียน</v-card-title>
				<v-card-text>
					<v-file-input v-model="files" placeholder="xlsx" label="File input" accept=".xlsx" prepend-icon="mdi-paperclip">
						<template v-slot:selection="{ text }">
							<v-chip small label color="primary">{{ text }}</v-chip>
						</template>
					</v-file-input>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" text @click="importDialog = false">ยกเลิก</v-btn>
					<v-btn
						color="green darken-1"
						text
						@click="
							importDialog = false;
							putStudent();
						"
						>ยืนยัน</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
	export default {
		mounted() {
			this.loading = false;
			this.getStudents();
		},
		watch: {
			files() {
				this.formdata.set("data", this.files);
			},
		},
		data() {
			return {
				search: "",
				loading: true,
				students: [],
				students_all: [],
				formdata: new FormData(),
				tests: [],
				current_shows: [],
				importDialog: false,
				files: null,
				limit: [10,20,50,100,1000,2000,5000],
				pagination: {
					limit: 10,
					current: 1,
					total: 0,
					pages: 1,
					next_page: 1,
					previous_page: 0,
				},
			};
		},
		methods: {
			onChangeSearch(val){
				if (_.isNull(val)) {
				this.search=""
				}
				this.pagination.current = 1;
				this.getStudents()
			},
			putStudent() {
				let app = this;
				this.loading = true;
				app.$http
					.post("/students/import", app.formdata, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then((resp) => {
						console.log(resp.data);
						window.location.reload();
						app.loading = false;
					})
					.catch((err) => {
						console.log(err.response);
						app.loading = false;
					});
			},
			getCaseColor(cases, index) {
				return cases > 2 ? "red lighten-3" : cases > 0 ? "orange lighten-3" : "green lighten-3";
			},
			// getRecentCase(cases, index) {
			// 	this.students[index].recent_case = cases.length ? cases[0].name : "ไม่พบ";
			// 	return this.students[index].recent_case;
			// },

			clearSearch() {
				this.search = null;
				this.getStudents();
			},

			getStudents() {
				let app = this;
				this.loading = true;
				app.$http
					.get(`https://api-kpsw-student-service.cloudary.dev/api/v1/student?page=${this.pagination.current}&limit=${this.pagination.limit}&search=${this.search}`)
					.then((res) => {
						app.students = res.data.data;
						app.pagination.current = res.data.meta.current_page;
						app.pagination.total = res.data.meta.total;
						app.pagination.pages = res.data.meta.pages;

						app.loading = false;
					})
					.catch((err) => {
						console.log(err);
						app.loading = false;
					});
			},
			changeLimit() {
				this.pagination.current = 1;
				this.getStudents();
			}
		},
		// watch: {
		//   search(val){
		//     console.log(val)
		//   }
		// },
	};
</script>

<style></style>
